import { isRouteErrorResponse, useRouteError } from "@remix-run/react";
import ErrorPage from "./ErrorPage";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { getPublicEnv } from "~/helpers";

function getHeaderAndMessage(error: unknown) {
  let header = "Unknown Error";
  let message = "An unknown error occurred.";

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      header = "Shop Return Page Not Found";
      message = "Please check with your retailer to make sure that the return page link is correct.";
    } else {
      header = `${error.status} ${error.statusText}`;
      message = error.data;
    }
  } else if (error instanceof Error) {
    header = error.name;
    message = error.message;
  }

  return { header, message };
}

export function GeneralErrorBoundary() {
  const error = useRouteError();
  if (!isRouteErrorResponse(error)) console.error(error);

  captureRemixErrorBoundaryError(error);

  const { header, message } = getHeaderAndMessage(error);

  if (getPublicEnv("REMIX_APP_ENVIRONMENT") !== "development") {
    return <ErrorPage header={header} message={message} />;
  } else {
    // log the error in development
    console.error(error);
    throw error;
  }
}
